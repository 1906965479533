import { Injectable } from '@angular/core';

import get from 'lodash-es/get';
import { Observable, ReplaySubject } from 'rxjs';

export interface IConfig {
    API_ENDPOINT: string;
    CHARGEBEE_SITE: string;
    CHARGEBEE_PUBLISH_KEY?: string;
    DEFAULT_SUBDOMAIN: string;
    production?: boolean;
}

type ConfigOption = keyof IConfig;

@Injectable({
    providedIn: 'root',
})
export class ConfigService {
    private config: IConfig = {} as IConfig;
    private readonly configSubject = new ReplaySubject<IConfig>(1);

    public loadConfig(environment: Record<string, unknown>): void {
        this.config = { ...(environment as unknown as IConfig) };

        this.configSubject.next(this.config);
    }

    public get<T>(key: ConfigOption) {
        return get(this.config, key) as T;
    }

    public getConfig() {
        // as the loadConfig is bootstrapped within APP_INITIALIZER this should always be available
        return this.config;
    }

    public getConfigObservable(): Observable<IConfig> {
        return this.configSubject.asObservable();
    }
}
