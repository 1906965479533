import { ElementRef, Injectable } from '@angular/core';

import { ConfigService } from '@passbot/angular/config';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { Estimate } from 'chargebee-typescript/lib/resources';
import { AdditionalData, Callbacks, ChargebeeComponent, ChargebeeInstance, Component, PaymentIntent } from '@chargebee/chargebee-js-types';
import { IBillingInfo, ISignup } from '@passbot/shared';

declare let Chargebee: any;

interface IEventCallbacks {
    [key: string]: (state: any) => void;
}

type CardComponent = ChargebeeComponent & Component;

@Injectable({
    providedIn: 'root',
})
export class ChargebeeService {
    public cbSiteId: string;
    private publishableKey: string;
    private cbInstance: ChargebeeInstance;

    constructor(private readonly configService: ConfigService, private readonly httpClient: HttpClient) {}

    public async inject(andInit = true) {
        this.cbSiteId = this.configService.get<string>('CHARGEBEE_SITE');
        this.publishableKey = this.configService.get<string>('CHARGEBEE_PUBLISH_KEY');

        await this.loadExternalScript(this.cbSiteId);

        if (andInit) {
            this.init();
        }
    }

    public init() {
        this.cbInstance = Chargebee.init({
            site: this.cbSiteId,
            ...(this.publishableKey ? { publishableKey: this.publishableKey } : {}),
            isItemsModel: true,
        });
        void this.cbInstance.load('functions');
    }

    public async getEstimate(itemPriceId: string, country: string, quantity?: number, vatNumber?: string) {
        const data = await firstValueFrom(
            this.httpClient.post<{ estimate: Estimate }>(`${this.configService.get('API_ENDPOINT')}/billing/estimate`, {
                itemPriceId,
                country,
                quantity,
                vatNumber,
            }),
        );

        return data.estimate;
    }

    public async renderCardComponent(element: ElementRef, options: any, callbacks: IEventCallbacks = {}) {
        await this.cbInstance.load('components');
        const component = this.cbInstance.createComponent('card' as any, options) as CardComponent;

        Object.keys(callbacks).forEach((key) => {
            component.on(key as any, callbacks[key as keyof IEventCallbacks] as Function); // eslint-disable-line
        });

        await component.mount(element.nativeElement);
        return component;
    }

    public async takePayment(
        component: CardComponent,
        amount: number,
        currency: string,
        additionalData: AdditionalData = {},
        callbacks: Callbacks = {},
    ) {
        // get payment intent
        const paymentIntent = await firstValueFrom(
            this.httpClient.post<PaymentIntent>(`${this.configService.get('API_ENDPOINT')}/billing/payment-intent`, { amount, currency }),
        );

        await component.authorizeWith3ds(paymentIntent, additionalData, callbacks);
    }

    public createCustomerAndSubscription(signup: Omit<ISignup, 'id'>, estimate: Estimate, paymentIntent: PaymentIntent, billing: IBillingInfo) {
        return firstValueFrom(
            this.httpClient.post<any>(`${this.configService.get('API_ENDPOINT')}/billing/create`, {
                billing,
                signup,
                paymentIntent,
                estimate,
            }),
        );
    }

    public validateVAT(countryCode: string, number: string) {
        return this.cbInstance.vat.validateVat({ country: countryCode, vat_number: number });
    }

    protected async loadExternalScript(cbSiteValue: string) {
        return new Promise<void>((resolve) => {
            const script = document.createElement('script');

            script.onload = () => {
                resolve();
            };

            script.src = 'https://js.chargebee.com/v2/chargebee.js';

            document.head.appendChild(script);
        });
    }
}
